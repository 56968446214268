import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import SectionHeader from "../components/sectionHeader"
import { mq } from "../styles/styles"

const GunsImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "guns.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)

const DetailsWrapper = styled.div`
  padding: 2rem 0;
  @media (min-width: ${mq.large}) {
    padding: 5rem 5rem;
    display: grid;
    grid-template-columns: 45% auto;
    grid-column-gap: 2rem;
  }
`

const Detail = styled.div`
  margin-bottom: 2rem;
  @media (min-width: ${mq.large}) {
    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 2 auto;
  justify-content: center;
`

const GunsImageWrapper = styled.div`
  grid-row: 1;
  grid-column: 2;
`

const Copy = styled.div`
  text-align: center;
`

const SubHeader = styled.h3`
  margin: 0;
  font-size: 1rem;
  margin-bottom: 0.1rem;
`

const MainDetails = () => (
  <DetailsWrapper>
    <Details>
      <Detail>
        <SectionHeader>Ceremony</SectionHeader>
        <Copy>
          <SubHeader>Saint John the Apostle Catholic Church</SubHeader>
          101 Oakcrest Manor Drive Northeast
          <br /> Leesburg, Virginia 20176
          <br />
          <strong>2:00pm</strong>
        </Copy>
      </Detail>
      <Detail>
        <SectionHeader>Cocktail Hour &amp; Reception</SectionHeader>
        <Copy>
          <SubHeader>Birkby House</SubHeader>
          109 Loudoun St Southwest
          <br /> Leesburg, Virginia 20175
          <br />
          <strong>4:30pm</strong>
        </Copy>
      </Detail>
    </Details>
    <GunsImageWrapper>
      <GunsImage />
    </GunsImageWrapper>
  </DetailsWrapper>
)

export default MainDetails
