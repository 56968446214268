import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/main-image"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import MainDetails from "../components/main-details"
import OtherDetails from "../components/other-details"
import HowTheyMet from "../components/how-they-met"

const MainImageWrapper = styled.div``

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `evan malone wedding`,
        `meagan leon wedding`,
        `evan and meaghan wedding`,
      ]}
    />
    <MainImageWrapper>
      <Image />
    </MainImageWrapper>
    <MainDetails />
    <OtherDetails />
    <HowTheyMet />
  </Layout>
)

export default IndexPage
