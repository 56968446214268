import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import SectionHeader from "../components/sectionHeader"
import { mq, gold } from "../styles/styles"

const copyOne = `Christmas Eve 2016 Meaghan was out for Christmas drinks with her sister and cousin at O’Sullivan’s Irish Pub in Arlington, VA, while Evan was there with another lady on a date (that turned out to be their first and last)!  When Evan went to the bar to pay his tab, Meaghan’s sister, Alicia, leaned over and casually said, “Do I know you?” A clever icebreaker that led to a fruitless discussion of how their paths may have previously crossed.  Alicia did, however, manage to smoothly introduce her beautiful older sister during the conversation. When Evan’s date returned, he politely said goodnight and walked her out of the bar.`
const copyTwo = `After bidding the young lady farewell, he paused for a moment, wondering if he should head home for the night. But he also remembered the lovely lady he had just met at the bar and decided to head back inside, hoping maybe for a second first date in one night! He bought Meaghan and her family a round of drinks, and the two spent the rest of the night talking at the bar—they clicked immediately. Evan asked Meaghan to go on a  second/first real date the next night, and that is when they realized just how close their lives had actually been to crossing paths. Within the next couple of weeks, both Evan and Meaghan realized they had found their soulmate—their Christmas miracle!`
const copyThree = `Almost two years later, Evan invited lots of close family and friends to the same Irish Pub where they met and first said “I love you” to propose! He even enlisted the help of some family to decorate the bar with Christmas decorations to make the moment even more special. It was the most perfect surprise that led us all together to celebrate their marriage and love for each other on October 5!`

const CvilleImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "cville.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)

const DetailsWrapper = styled.div`
  @media (min-width: ${mq.large}) {
    padding: 5rem 5rem;
    display: grid;
    grid-template-columns: 45% auto;
    grid-column-gap: 2rem;
  }
`

const Detail = styled.div`
  margin-bottom: 2rem;
  padding: 2rem 1rem;
  @media (min-width: ${mq.large}) {
    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 2 auto;
  justify-content: center;
  p:first-of-type:first-letter {
  color: ${gold};
  float: left;
  font-family: Georgia;
  font-size: 6rem;
  line-height: 5rem;
  padding-right: .5rem;
}
`

const GunsImageWrapper = styled.div`
  grid-row: 1;
  grid-column: 2;
`

const Copy = styled.p`
  text-align: left;
`

const SubHeader = styled.h3`
  margin: 0;
  font-size: 1rem;
  margin-bottom: 0.1rem;
`

const HowTheyMet = () => (
  <DetailsWrapper>
    <Details>
      <Detail>
        <SectionHeader>How They Met</SectionHeader>
        <Copy>
          {copyOne}
        </Copy>
        <Copy>
          {copyTwo}
        </Copy>
        <Copy>
          {copyThree}
        </Copy>
      </Detail>
    </Details>
    <GunsImageWrapper>
      <CvilleImage />
    </GunsImageWrapper>
  </DetailsWrapper>
)

export default HowTheyMet
