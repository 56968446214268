import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import SectionHeader from "../components/sectionHeader"
import { mq } from "../styles/styles"
import WSLogo from "../images/svg/williamandsonoma.svg"
import AmazonLogo from "../images/svg/Amazon.com-Logo.svg"
import MacysLogo from "../images/svg/Macys_logo.svg"

const GunsImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "smiley.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)

const DetailsWrapper = styled.div`
  padding: 2rem 0;
  @media (min-width: ${mq.large}) {
    padding: 5rem 5rem;
    display: grid;
    grid-template-columns: 45% auto;
    grid-column-gap: 2rem;
    grid-template-areas: "detail image";
    & > *:first-of-type {
      grid-area: detail;
    }
    & > *:nth-of-type(2) {
      grid-area: image;
    }
    grid-template-areas: "image detail";
  }
`

const Detail = styled.div`
  margin-bottom: 2rem;
  @media (min-width: ${mq.large}) {
    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 2 auto;
  justify-content: center;
`

const GunsImageWrapper = styled.div`
  grid-row: 1;
  grid-column: 2;
`

const BlockWrapper = styled.div`
padding: 1rem 2rem;
align-items: center;
  > div:first-of-type {
    margin-bottom 1rem;
  }
  @media (min-width: ${mq.large}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    > div {
      padding: 1rem 1rem;
      flex: 0 0 50%;
      max-width: 50%;
    }
    grid-column-gap: 2rem;
    > div:first-of-type {
      margin-bottom 0;
  }
  }
`

const Block = styled.div`
  text-align: center;
`

const SubHeader = styled.h3`
  margin: 0;
  font-size: 1rem;
  margin-bottom: 0.1rem;
`

const ImageLink = styled.a`
  opacity: 0.5;
  transition: opacity 250ms ease-in-out;
  &:hover {
    opacity: 1;
  }
`

const MainDetails = () => (
  <DetailsWrapper>
    <Details>
      <Detail>
        <SectionHeader>Accommodations</SectionHeader>
        <BlockWrapper>
          <Block>
            <SubHeader>Best Western</SubHeader>
            <a href="https://www.bestwestern.com/en_US/book/hotel-rooms.47076.html?groupId=E48ED6V8">
              726 E Market Street
              <br /> Leesburg, Virginia 20176
              <br />
            </a>
            <a href="tel:7037779400">(703) 777-9400</a>
          </Block>
          <Block>
            <SubHeader>Hampton Inn and Suites</SubHeader>
            <a href="http://hamptoninn.hilton.com/en/hp/groups/personalized/W/WASLBHX-LMW-20191004/index.jhtml">
              117 Fort Evans Road
              <br /> Leesburg, Virginia 20176
              <br />
            </a>
            <a href="tel:7036698640">(703) 669-8640</a>
          </Block>
        </BlockWrapper>
      </Detail>
      <Detail>
        <SectionHeader>Registries</SectionHeader>
        <BlockWrapper>
          <Block>
            <ImageLink
              alt="william and sonoma logo"
              href="https://www.williams-sonoma.com/registry/q75mb5tgn2/registry-list.html"
            >
              <WSLogo />
            </ImageLink>
          </Block>
          <Block>
            <ImageLink href="https://www.amazon.com/wedding/share/goinghomemalone">
              <AmazonLogo />
            </ImageLink>
          </Block>
          <Block>
            <ImageLink href="https://www.macys.com/wgl/registry/guest/7089148">
              <MacysLogo />
            </ImageLink>
          </Block>
        </BlockWrapper>
      </Detail>
    </Details>
    <GunsImageWrapper>
      <GunsImage />
    </GunsImageWrapper>
  </DetailsWrapper>
)

export default MainDetails
